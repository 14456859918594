body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-left: 18.3%;
  margin-top: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bank-page,
.individual-insurance-page,
.tax-page,
.individual-insurance-employer-page,
.annuities-page {
  font-family: 'FS Elliot Pro';
  overflow: auto;
}

#pds-table-docTable {
  margin-left: 18.4%;
  width: 61.5%;
  margin-bottom: 50px;
  min-width: 705px;
}

.pds-select-group {
  margin-left: 17.7%;
  margin-top: 50px;
  margin-bottom: 25px;
  width: 63%;
  min-width: 720px;
}

.filter-dropdown {
  max-width: 400px;
  display: table-cell;
}

.pagination-dropdown {
  display: inline-block;
  position: relative;
  top: -7px;
}

.filter-label {
  margin-left: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
}

.toggle-filter {
  display: block;
  margin-left: 15px;
}

.clear-filter-button {
  font-weight: bold;
  border-bottom: 2px dotted;
  font-size: 95%;
  color: #0061A0;
  background: none;
  margin-left: 10px;
  cursor: pointer;
  font-family: 'FS Elliot Pro';
}

.pds-alert {
  margin-left: 18.5%;
  margin-bottom: 50px;
  min-width: 61.5%;
}

.pds-label {
  font-family: 'FS Elliot Pro';
}

.pds-enterpriseFooter {
  margin-top: auto;
}

.member-th {
  width: 25%;
}

.employer-th {
  width: 16.66%;
  font: bold;
}

.triangle-default {
  width: 15px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  background-image: url("./images/default.png");
}

.triangle-asc {
  width: 12px;
  margin-left: 3px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  background-image: url("./images/asc.png");
}

.triangle-desc {
  width: 12px;
  margin-left: 3px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  background-image: url("./images/desc.png");
}

.pds-pagination-item {
  cursor: pointer;
}

.bank-page-parent,
.individual-insurance-page-parent,
.individual-insurance-employer-page-parent,
.tax-page-parent,
.annuities-page-parent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.read {
  font-family: 'FS Elliot Pro';
  font-weight: normal;
}

.unread {
  font-family: 'FS Elliot Pro';
  font-weight: bold;
}

.disabled {
  pointer-events: none;
}

.not-allowed {
  cursor: not-allowed;
}